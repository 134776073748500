<template>
  <Login />
</template>
<script>
import Login from "@/components/auth/Login";

export default {
  name: "Auth",
  components: { Login },
};
</script>